@import url(https://fonts.googleapis.com/css?family=Lato);

.contact-page {
  display: block;
}

.contact-title {
  margin-bottom: 30px;
  font-family: Lato;
}

.confirmation-message {
  font-family: Futura, Trebuchet MS;
}

.contact-container {
  margin-right: auto;
  margin-left: auto;
  width: 60%;
}

form {
  height: 100px;
}

.contact-form {
  background-color: #f2f2f2;
  border-radius: 5px;
  text-align: center;
}

.form-group {
  margin-bottom: 5px;
  height: 100px;
}

label {
  font-family: Futura, Trebuchet MS;
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 30px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #333;
  transition: border-color 0.3s ease;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
  outline: none;
  border-color: #6c63ff;
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
textarea::placeholder {
  color: #999;
}

.submit-button {
  margin-top: 80px;
  width: 100%;
  padding: 10px;
  background-color: #6c63ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #563eff;
}

textarea {
  resize: none;
  height: 100px;
}

.marcorusso {
  margin-top: -50px;
  margin-bottom: -50px;
  width: 300px;
  height: 300px;
}

.logo-div {
  text-align: center;
}

@media (max-width: 768px) {
  .marcorusso {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100px;
    height: 100px;
  }
  label {
    font-size: 10px;
  }

  .form-group {
    margin-bottom: 5px;
    height: 50px;
  }
}

@media (max-width: 1280px) {
  .marcorusso {
    margin-top: 5px;
    margin-bottom: 0px;
    width: 100px;
    height: 100px;
  }
  .contact-container {
    margin-right: auto;
    margin-left: auto;
    width: 30%;
  }
}

@media (max-width: 1024px) {
  .marcorusso {
    margin-top: 5px;
    margin-bottom: 0px;
    width: 60px;
    height: 60px;
  }
  .contact-container {
    margin-right: auto;
    margin-left: auto;
    width: 30%;
  }
  .contact-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  input[type="text"],
  input[type="email"],
  textarea {
    padding: 2px;
  }

  label {
    font-size: 16px;
  }
}
