@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url("https://fonts.cdnfonts.com/css/arial-mt"); /*Avenir Next*/

.prova-list {
  margin-left: 80px;
  margin: 50px;
  line-height: 30px;
}

.upper-item {
  margin-bottom: 40px;
  font-size: 30px;
  font-family: "Arial Rounded MT", sans-serif;
}

.upper-item-bigger {
  margin-bottom: 40px;
  font-size: 50px;
  font-family: "Arial Rounded MT", sans-serif;
}

.lower-item {
  margin-bottom: 20px;
  font-size: 26px;
  font-family: "Avenir", sans-serif;
}

.half-list {
  margin-bottom: 100px;
}

.project-page-wrapper {
  display: flex;
}

.project-page-left {
  flex: 1;
}

.aaterstad-page-left {
  flex: 1;
  margin-left: -100px;
}

.aaterstad-image {
  width: 500px;
}

.project-page-video-left {
  margin-right: auto;
  flex: 1;
}

.faccia-da-morto-right {
  margin-left: 5%;
  margin-right: 15%;
}

.project-page-right {
  margin-left: 5%;
  margin-right: 5%;
}

.project-page-image {
  width: 700px;
}

.paris-image {
  width: 400px;
}

.project-page-video iframe {
  width: 500px;
}

.taller-image {
  width: 550px;
}

.project-description {
  font-family: "Avenir", sans-serif;
  font-size: 26px;
}
.aaterstad-project-description {
  font-family: "Avenir", sans-serif;
  font-size: 26px;
}

.project-video-description {
  font-family: "Avenir", sans-serif;
  font-size: 26px;
}

@media (max-width: 768px) {
  .project-video-description {
    font-size: 12px;
    width: 60%;
    margin-left: 150px;
  }
  .project-page-wrapper {
    display: block;
    align-items: center;
    text-align: center;
  }

  .project-page-left {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }

  .project-page-image {
    width: auto;
    max-width: 200px;
    margin-left: 100px;
  }

  .aaterstad-image {
    margin-left: 100px;
    width: 60%;
  }

  .taller-image {
    margin-top: 50px;
    max-width: 200px;
  }

  .project-page-video iframe {
    margin-top: -80px;
    width: auto;
    height: auto;
  }

  .project-page-video {
    display: flex;
    justify-content: center;
    margin-left: 100px;
    align-items: center;
    margin-top: 50px;
  }

  .project-description {
    margin-left: 100px;

    width: 80%;
    font-size: 12px;
  }

  .aaterstad-project-description {
    margin-left: 150px;
    width: 60%;
    font-size: 12px;
  }

  .upper-item {
    margin-bottom: 20px;
    font-size: 15px;
  }

  .upper-item-bigger {
    margin-bottom: 20px;
    font-size: 25px;
  }

  .lower-item {
    margin-bottom: 10px;
    font-size: 12px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .project-page-video-left {
    order: 2; /* Assegna un ordine inferiore a project-page-video-left */
  }

  .project-page-left {
    order: 2;
  }

  .faccia-da-morto-right {
    display: flex;
    order: 1; /* Assegna un ordine inferiore a project-page-right */
    margin-left: 1%;
  }

  .project-page-right {
    display: flex;
    order: 1; /* Assegna un ordine inferiore a project-page-right */
    margin-left: 1%;
  }

  .right-items {
    order: 2;
    margin-left: 50px;
    margin-right: 1%;
  }

  .left-items {
    margin-left: 10%;
    margin-right: 5%;
    order: 2;
    width: 50%;
  }

  .paris-image {
    margin-top: -100px;
    margin-left: 98px;
    max-width: 80%;
  }
}

@media (max-width: 1280px) {
  .project-page-left {
    margin-left: -100px;
  }
  .project-page-video-left {
    margin-left: -100px;
  }
  .aaterstad-image {
    max-width: 500px;
  }
  .lower-item {
    font-size: 20px;
  }

  .upper-item {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  .aaterstad-image {
    max-width: 400px;
  }

  .project-page-left {
    margin-left: -100px;
  }
  .lower-item {
    font-size: 20px;
  }
  .project-page-video-left {
    margin-left: -100px;
  }
  .upper-item {
    font-size: 20px;
  }
}
