.about-page {
  display: flex;
}

.paragraph-div {
  width: 80%;
  margin-left: -80px;
  margin-bottom: 100px;
}

.about-paragraph {
  font-family: "Avenir", sans-serif;
  font-size: 22px;
  line-height: 1.1;
  text-align: justify;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.about-images {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.about-image {
  margin: auto;
  width: fit-content;
  height: 300px;
}

@media (max-width: 768px) {
  .about-page {
    display: block;
    align-items: center;
    text-align: center;
  }
  .paragraph-div {
    width: 80%;
    margin-left: 35px;
  }

  .about-paragraph {
    margin-top: 0px;
    margin-left: 40px;
    font-size: 16px;
  }
  .about-image {
    height: 70px;
  }
  .about-images {
    margin-left: 100px;
  }
}
@media (max-width: 1279px) {
  .about-paragraph {
    margin-right: 100px;
    line-height: 1.2;
    font-size: 16px;
  }

  .about-image {
    height: 100px;
  }
  .about-images {
    margin-right: 100px;
  }
}

@media (min-width: 1280px) and (max-width: 1680px) {
  .about-paragraph {
    margin-right: 100px;
    line-height: 1.5;
    font-size: 20px;
  }

  .about-image {
    height: 200px;
  }
  .about-images {
    margin-right: 100px;
  }
}
