@import url(https://fonts.googleapis.com/css?family=Lato);

.navbar {
  margin-left: 50px;
  margin-right: 150px;
  font-family: Lato;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  width: auto;
  height: auto;
  top: 1px;
}

.logo {
  margin-left: -5px;
  margin-top: 10px;
  margin-bottom: 30px;
  max-width: 180px;
  height: auto;
}

.name {
  font-size: 26px;
}

.job {
  font-family: Futura, Trebuchet MS;
  font-size: 20px;
}

.categories {
  margin-bottom: 180px;
}

.category {
  font-family: Futura, Trebuchet MS;
  background-color: transparent;
  border: none;
  font-size: inherit;
  cursor: pointer;
  display: block; /* Imposta i bottoni come elementi di blocco */
  margin-bottom: 10px; /* Aggiunge uno spazio tra i bottoni */
  margin-left: -10px;
  color: black;
}

.language-icons {
  display: none;
}

.menu-icon {
  width: 50px;
  border-radius: 40%;
  cursor: pointer;
}

.flag-icon {
  margin-bottom: 5px;
  cursor: pointer;
  margin-right: auto;
  width: 30px;
  border-radius: 40%;
}

.language-icons.open {
  display: flex;
  animation: fadeIn 0.3s ease-in-out;
  flex-direction: column;
}

.social-icons {
  display: flex;
  margin-bottom: 30px;
}

.social-icon {
  width: 40px;
  margin-right: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.page-link {
  text-decoration: none;
  color: black;
}

.svg-icon {
  margin-right: 10px;
  width: 30px;
  height: auto;
}

@media (max-width: 768px) {
  .navbar {
    margin: auto;
    display: flex;
    position: relative;
    justify-content: space-evenly;
  }

  .categories {
    display: none;
  }

  .logo {
    width: 100px;
  }

  .link-language-div {
    text-align: center;
  }
  .social-icon {
    margin-top: 15px;
  }
}

@media (max-width: 1280px) {
  .social-icons {
    margin-bottom: 10px;
  }
  .categories {
    margin-bottom: 100px;
  }
}

@media (max-width: 1024px) {
  .social-icon {
    width: 15px;
    margin-right: 10px;
  }

  .social-icons {
    margin-bottom: 10px;
  }
  .categories {
    margin-bottom: 10px;
  }
}

@media (max-width: 1366px) {
  .social-icon {
    width: 25px;
    margin-right: 5px;
  }

  .social-icons {
    margin-bottom: 10px;
  }
  .categories {
    margin-bottom: 60px;
  }
  .link-language-div {
    font-size: 14px;
  }
}

@media (min-width: 1367px) and (max-width: 1680px) {
  .social-icon {
    width: 15px;
    margin-right: 10px;
  }
  .link-language-div {
    font-size: 14px;
  }
  .categories {
    margin-bottom: 100px;
  }
}

.hidden {
  display: none;
}
