@import url(https://fonts.googleapis.com/css?family=Lato);

.secondary-navbar {
  margin-left: 50px;
  margin-right: 150px;
  font-family: Lato;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  width: auto;
  height: 50px;
  top: 1px;
}

.secondary-logo {
  margin-left: -5px;
  margin-top: 12px;
  margin-bottom: 324px;

  max-width: 180px;
  height: auto;
}

.secondary-name {
  font-size: 26px;
}

.secondary-job {
  font-family: Futura, Trebuchet MS;
  font-size: 20px;
}

.secondary-language-icons {
  display: none;
}

.secondary-menu-icon {
  width: 50px;
  border-radius: 40%;
  cursor: pointer;
}

.secondary-flag-icon {
  margin-bottom: 5px;
  cursor: pointer;
  margin-right: auto;
  width: 30px;
  border-radius: 40%;
}

.secondary-language-icons.open {
  display: flex;
  animation: fadeIn 0.3s ease-in-out;
  flex-direction: column;
}

.secondary-social-icons {
  display: flex;
  margin-bottom: 30px;
}

.secondary-social-icon {
  width: 40px;
  margin-right: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.secondary-page-link {
  text-decoration: none;
  color: black;
}

.secondary-svg-icon {
  margin-right: 10px;
  width: 30px;
  height: auto;
}

.hidden {
  display: none;
}

@media (max-width: 768px) {
  .secondary-navbar {
    margin: auto;
    margin-bottom: 200px;
    display: flex;
    position: relative;
    justify-content: space-evenly;
  }

  .secondary-logo {
    width: 100px;
  }
  .secondary-social-icons {
    margin-top: 17px;
    margin-bottom: 10px;
  }
  .secondary-link-language-div {
    margin-top: 2px;
  }
}

@media (max-width: 1280px) {
  .secondary-logo {
    margin-bottom: 204px;
  }
  .secondary-social-icons {
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) {
  .secondary-social-icon {
    width: 15px;
    margin-right: 10px;
  }

  .secondary-social-icons {
    margin-bottom: 10px;
  }
}

@media (max-width: 1366px) {
  .secondary-social-icon {
    width: 25px;
    margin-right: 5px;
  }

  .secondary-social-icons {
    margin-bottom: 10px;
  }

  .secondary-link-language-div {
    font-size: 14px;
  }
}

@media (min-width: 1367px) and (max-width: 1680px) {
  .secondary-logo {
    margin-bottom: 242px;
  }
  .secondary-social-icon {
    width: 15px;
    margin-right: 10px;
  }
  .secondary-link-language-div {
    font-size: 14px;
  }
}
