.home-container {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
}

.cellulare {
  display: none;
}

.mosaic {
  margin-top: 5px;
  flex-wrap: wrap;
  justify-content: flex-end; /* Posiziona mosaic-right alla destra di mosaic-left */
  column-count: 3; /* Suddivide in tre colonne */
  column-gap: 15px; /* Aggiunge uno spazio di 15px tra le colonne */
}

.mosaic-image {
  position: relative;
  width: 100%; /* Occupa tutto lo spazio disponibile nella colonna */
  margin-bottom: 15px;
  margin-right: 15px;
  margin-left: -60px;
}

.cellulare-image {
  position: relative;
  width: 100%; /* Occupa tutto lo spazio disponibile nella colonna */
  margin-bottom: 15px;
  margin-right: 15px;
  margin-left: -60px;
}

.mosaic-image img {
  max-width: 100%;
  margin-left: 0px;
  height: auto;
}

.cellulare-image img {
  max-width: 100%;
  margin-left: 0px;
  height: auto;
}

.text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  font-family: "Avenir", sans-serif;
}

.mosaic-image:hover .text-overlay {
  opacity: 1;
}

.mosaic-image:hover .kyle-text-overlay {
  opacity: 1;
}

.text-overlay p {
  width: 100%;
  font-size: 18px;
}

.hidden {
  display: none;
}

@media (max-width: 768px) {
  .mosaic {
    display: none;
  }

  .cellulare {
    margin-right: -50px;
    margin-left: 70px;
    display: block; /* Mostra .cellulare al posto di .mosaic */
    column-count: 2; /* Suddivide .cellulare in due colonne */
    gap: 15px;
  }

  .home-container {
    overflow-x: hidden;
    display: block;
  }

  .text-overlay p {
    font-size: 12px;
  }
}

@media (max-width: 1024px) {
  .mosaic {
    display: none;
  }
  .cellulare {
    display: block; /* Mostra .cellulare al posto di .mosaic */
    column-count: 2; /* Suddivide .cellulare in due colonne */
    gap: 15px;
  }

  .text-overlay p {
    font-size: 12px;
  }
}

@media (max-width: 1366px) {
  .text-overlay p {
    font-size: 12px;
  }
}
